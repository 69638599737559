import React from 'react'

function footer() {
    return (
        <div class="social text-center mt-5">
        <h3 className='footer'>Social Media</h3>
        <div class="clearfix mt-3"></div>
        <ul className='icons'>  
          <li><a href="https://twitter.com/elysiumlila" target="_blank"><i class="fab fa-twitter"></i></a></li>
          <li><a href="https://t.me/+8jApNi5CgaBjY2Fi" target="_blank"><i class="fab fa-telegram"></i></a></li>
          <li><a href="https://discord.com/invite/Ght9xvFbKV" target="_blank"><i class="fab fa-discord"></i></a></li>
          <li><a href="https://www.facebook.com/ElysiumLila" target="_blank"><i class="fab fa-facebook"></i></a></li>
          <li><a href="https://www.instagram.com/elysiumlila/" target="_blank"><i class="fab fa-instagram"></i></a></li>
        </ul>
      </div>
   
  )
}

export default footer