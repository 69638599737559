import React from 'react'
import { BounceLoader } from 'react-spinners'

function Spinner() {
  return (
      <div className='page-loader'>
          <BounceLoader color='blue' size={150}></BounceLoader>
     </div>
  )
}

export default Spinner