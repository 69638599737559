import React, { useState, useEffect } from "react";
import "./App.css";

import "./assets/main.css";
import Spinner from "./components/Spinner";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Claim from "./components/Claim";
import Home from "./components/Home";
import Referral from "./components/Referral";
import Buy from "./components/Buy";
import MainPage from "./components/mainPage";
import Web3 from "web3";
import { ethers } from "ethers";
import Header from "./components/Header";
import Main from "./components/Navbar";
import {
  useUserSettingsQuery,
  useUserLoginMutation,
  useGetTransactionsMutation,
  useUserWalletBalanceMutation,
} from "./components/store/ReduxApis";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector } from "./connectors/index";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  let [loading, setLoading] = useState(true);

  const [defaultAccont, setDefaultAccount] = useState(null);
  const [userBalence, setUserBalence] = useState(null);
  const [wallet, setWallet] = useState(false);
  const [error, setError] = useState();
  const [phising, setLoadingPhising] = useState(true);
  const [nugenBal, setNugenBal] = useState("");

  const [referralCodes, setReferralCodes] = useState();
 

  

  const { account, active, chainId, activate, deactivate } =
    useWeb3React();

  const [createPost, responseInfo] = useUserLoginMutation();

  const [createPost3, responseInfo3] = useGetTransactionsMutation();

  const [createPost4, responseInfo4] = useUserWalletBalanceMutation();

  console.log(chainId, active, "800")



  console.log(responseInfo);

  console.log(responseInfo3);

  console.log(responseInfo4, "referral")

  const data = useUserSettingsQuery();
  console.log(data, "500");



  const nugenToken = data?.data?.data?.smartContractAddress?.elsyium;

  console.log(nugenToken, "500")

  const ico = data?.data?.data?.smartContractAddress?.ico;

  const saleid = data?.data?.data?.sale_id;
  const sale = data?.data?.data?.sale;

  const chainID = data?.data?.data?.chainid;
  const blockExplorer = data?.data?.data?.blockExplorerUrls;
  const decimals = data?.data?.data?.decimals;
  const rpc = data?.data?.data?.rpcUrl;
  const networkName = data?.data?.data?.network;

  console.log(typeof(chainID), chainID, "800");

  console.log(chainId, "800")


  
 console.log(sale, saleid, "550")

  console.log(injectedConnector?.supportedChainIds, "injected")


  console.log(active, "800");

  console.log(account);

  const handleConnectWallet = async () => {
    console.log(chainId, "800");
    try {
      if(window.web3.currentProvider.isMetaMask){
      if (chainId !== 56) {
         try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
          //  params: [{ chainId: '0x61' }]
          params: [{ chainId: `0x${Number(chainID).toString(16)}` } ],
          });
          await activate(injectedConnector);
          sessionStorage.setItem("isWalletConnected", true);
         } catch(err) {
          console.log(err.code, "codeerror")
          if(err.code === 4902) {
            try {
 
           
            console.log("chainID")
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: `0x${Number(chainID).toString(16)}`,
                  chainName: `${networkName}`,
                  nativeCurrency: {
                    name: `${networkName}`,
                    symbol: "BNB",
                    decimals: 18
                  },
                  rpcUrls: [
                    `${rpc}`,
                  ],
                  blockExplorerUrls: [`${blockExplorer}`]
                },
              ],
            });
            await activate(injectedConnector);
            console.log("hello", "800")
            
          
          console.log(err)
         } catch(err) {
          console.log(err)
         }
            
        }}}
        } 
      }  catch(err) {
        toast.warning("Please Connect Wallet", {
          position: "top-center",
        })
        console.log(err)
      }
  };

  const handleDisconnectWallet = () => {
    if (active) {
      deactivate(injectedConnector);
      sessionStorage.setItem("isWalletConnected", false);
    }
  };



  const btnClick = () => {
    console.log("Hello");
    setLoadingPhising(false);
  };

  useEffect(() => {
    const connectWalletOnPageLoad = async () => {
      if (sessionStorage?.getItem("isWalletConnected") === "true") {
        try {
          await activate(injectedConnector);
          sessionStorage.setItem("isWalletConnected", true);
          
        } catch (ex) {
          console.log(ex);
        }
      }
    };
    connectWalletOnPageLoad();

    setTimeout(() => {
      setLoading(false);
    }, 2500);
    
  }, []);

  console.log(responseInfo);

  useEffect(() => {

    if(account) {
     createPost(newPost5);
      const store = localStorage.setItem("token", responseInfo?.data?.token)
       createPost3(newPost);
     
  
      console.log(responseInfo4, "800")

      setTimeout(() => {
        createPost4(newPost)
      }, 2500)
     

    } 

   
   

    console.log("Hello");

    
  
  }, [account]);

  console.log(responseInfo4);
  console.log(referralCodes);




  useEffect(() => {
    
    balance()
    const window_location = window.location.origin;

    const refferal =
      window_location + "/#/invite?referal=" ;

      if(window.location.href.includes( "/#/invite?referal=")) {
        const storage = localStorage.setItem("url", window.location.href);
        const last = window.location.href.slice(window.location.href?.length - 8);
        const storage2 = sessionStorage.setItem("Referralcode", last);

      }
    

    setReferralCodes(refferal);
   
 
    getUserBalence(account);

   
  


  });



  console.log(ico, "ico");

  const newPost = {
    user_wallet: account,
  };

  const getItem = sessionStorage.getItem("Referralcode")

  const newPost5 = {
    user_wallet: account,
    code: getItem,
  };





  console.log(newPost5, "getData")

  const accountWallet = (newAccount) => {
    setDefaultAccount(newAccount);
    


  };

  const getUserBalence = (address) => {
    try {
      window.ethereum
      .request({ method: "eth_getBalance", params: [address, "latest"] })
      .then((balence) => {
        setUserBalence(ethers.utils.formatEther(balence));
      });
    } catch (err) {
      console.log(err)
    }
    
  };

  function floorFigure(figure, decimals){
    if (!decimals) decimals = 2;
    var d = Math.pow(10,decimals);
    return (parseInt(figure*d)/d).toFixed(decimals);
};

console.log(account, "8800")



  const balance = async () => {
    try {
   

      await new Web3(window.web3.currentProvider);
      window.web3 = new Web3(window.web3.currentProvider);

  

      const nugenBalence = await new window.web3.eth.Contract(
        JSON.parse(process.env.REACT_APP_CONTRACT_ABI),
        nugenToken
      );

      const nugenContract = await nugenBalence.methods
        .balanceOf(account)
        .call();
        setNugenBal(floorFigure(nugenContract / Math.pow(10, 18), 5))

     

    } catch (err) {
      console.log(err, "eerr");
    }
  };

 

  console.log(nugenBal, "8800");


 

  return (
    <div>
     <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>  
      {loading === true ? (
        <Spinner></Spinner>
      ) : (
        <Router>
          {phising === true ? <Main btn={() => btnClick()} data={data}></Main> : ""}

          <Header
          
            connectWallet={(e) => handleConnectWallet(e)}
            active={active}
            disconnectWallet={() => handleDisconnectWallet()}
            account={account}
            wallet={wallet}
            defaultAccont={account}
            accountBal={userBalence}
          ></Header>

          <Routes>
            <Route path="/" element={<Home active={active}></Home>}></Route>
            <Route
              path="/userwallet"
              element={
                <MainPage
                  balance={() => balance()}
                  getTx={responseInfo3}
                  active={active}
                  walletBalance={userBalence}
                  defaultAddress={account}
                  NugenBalence={nugenBal}
                ></MainPage>
              }
              account={account}
            ></Route>
            <Route
              path="/claim"
              element={
                <Claim
                  account={account}
                  active={active}
                ></Claim>
              }
            ></Route>
            <Route
              path="/buy"
              element={<Buy defaultAddress={account}  refcode={responseInfo4?.data?.refcode} active={active}></Buy>}
            ></Route>

            <Route
              path="/referal"
              element={
                <Referral
                 
                  active={active}
                  code={referralCodes}
                  account={account}
                  newCode={responseInfo4?.data?.code}
                ></Referral>
              }
            ></Route>
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
