import React, { useEffect, useState } from 'react'
import { useClaimTransactionsMutation, useUserSettingsQuery } from './store/ReduxApis'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from 'web3';
import { useNavigate } from 'react-router-dom';

function Claim({ account, active}) {

    
  const [createPost, responseInfo] = useClaimTransactionsMutation();

  const [loading, setLoading] = useState(false)
  const [rewardloading, setRewardLoading] = useState(false)
  const [partnershiploading, setPartnershipLoading] = useState(false)
  const [adviosryloading, setAdvisoryLoading] = useState(false)
  const [reserveloading, setReserveLoading] = useState(false)

  const [partnershipBal, setPartnershipBal] = useState("")

  const [operationBal, setOperationBal] = useState("");
  const [advisoryBal, setAdvisoryBal] = useState("");

  const [reserveBal, setReserveBal] = useState("")
  const [rewardBal, setRewardBal] = useState("")
 

  

  


  const navigate = useNavigate()

  console.log(responseInfo);

  const data = useUserSettingsQuery();

  const operationAddress = data?.data?.data?.smartContractAddress?.operational
  const rewardAddress = data?.data?.data?.smartContractAddress?.rewardpool
  const advisoryAddress = data?.data?.data?.smartContractAddress?.advisory
  const partnershipAddress = data?.data?.data?.smartContractAddress?.partnership
  const reserveAddress = data?.data?.data?.smartContractAddress?.reserve




  


  console.log(process.env.REACT_APP_CLAIM_ADDRESS)


  console.log(responseInfo)

  function floorFigure(figure, decimals){
    if (!decimals) decimals = 2;
    var d = Math.pow(10,decimals);
    return (parseInt(figure*d)/d).toFixed(decimals);
};



const balance = async () => {
  try {
 

    await new Web3(window.web3.currentProvider);
    window.web3 = new Web3(window.web3.currentProvider);
  


    const market = await new window.web3.eth.Contract(
      JSON.parse(process.env.REACT_APP_OPERATIONAL_ABI),
      operationAddress
    );

    const marketContract = await market.methods.rewardInfo(account).call();

    console.log(marketContract, "375s");
    console.log(typeof marketContract);
    setOperationBal(floorFigure(marketContract / Math.pow(10, 18), 5));

    const team = await new window.web3.eth.Contract(
      JSON.parse(process.env.REACT_APP_REWARD_ABI),
      rewardAddress
    );

    const teamContract = await team.methods.rewardInfo(account).call();

    console.log(teamContract);
    setRewardBal(floorFigure(teamContract / Math.pow(10, 18), 5));

    const operation = await new window.web3.eth.Contract(
      JSON.parse(process.env.REACT_APP_ADVISORY_ABI),
      advisoryAddress
    );

    const operationContract = await operation.methods
      .rewardInfo(account)
      .call();

    console.log(operationContract, "398s");
    setAdvisoryBal(floorFigure(operationContract / Math.pow(10, 18), 5));

  



    const reserve = await new window.web3.eth.Contract(
      JSON.parse(process.env.REACT_APP_RESERVE_ABI),
       reserveAddress
    );

    const reserveContract = await reserve.methods.rewardInfo(account).call();

    console.log(reserveContract);
    setReserveBal(floorFigure(reserveContract / Math.pow(10, 18), 5));

    console.log(reserveBal);

    const partnership = await new window.web3.eth.Contract(
      JSON.parse(process.env.REACT_APP_PARTNERSHIP_ABI),
       partnershipAddress
    );

    const partnershipContract = await partnership.methods.rewardInfo(account).call();

    console.log(partnershipContract);
    setPartnershipBal(floorFigure(partnershipContract / Math.pow(10, 18), 5));


   

  } catch (err) {
    console.log(err, "eerr");
  }
};

useEffect(() => {
  balance()
})






  
  
  
const operationclaim = async () => {

try {
  await new Web3(window.web3.currentProvider);
  window.web3 = new Web3(window.web3.currentProvider);

  setLoading(true)
  
  const accountResponse = await window.web3.eth.getAccounts();
  console.log(accountResponse)
  const instance = accountResponse[0];
  
  console.log(instance)
  
  const claimContract = new window.web3.eth.Contract(JSON.parse(process.env.REACT_APP_OPERATIONAL_ABI), operationAddress);
  
  console.log(claimContract)
  
  const approve = await claimContract.methods.claim().send({from: instance});
  
  console.log(approve)

  const marketAmount = approve?.events?.Claim?.returnValues?.amount;

  console.log(marketAmount / Math.pow(10, 18), "amo")
  
  if(approve) {
   
  
    createPost({
      from: account,
      to: operationAddress,
      amount: floorFigure(marketAmount / Math.pow(10, 18), 5),
      txnid: approve.transactionHash,
      type: "operational",
  
    })
    toast.success("Operational Claim added successfully", {
      position: "top-center",
    })
    navigate("/Userwallet")


    
    setLoading(false)
    const operationalContractSam = await claimContract.methods.rewardInfo(account).call();

    console.log(operationalContractSam, "val");
    setOperationBal(floorFigure(operationalContractSam / Math.pow(10, 18), 5));
    
    
    
   

    
  
  } else {
  toast.error("Please Try Again Later", {
    position: "top-center",
  })
  }
} catch(err) {
  if(err.code === 4001) {
    toast.info("User reject the Request ", {
      position: "top-center",
    })
    setLoading(false)
  }
  console.log(err)
}

}
  
const rewardclaim = async () => {
try {


  await new Web3(window.web3.currentProvider);
  window.web3 = new Web3(window.web3.currentProvider);

  setRewardLoading(true)
  
  const accountResponse = await window.web3.eth.getAccounts();
  console.log(accountResponse)
  const instance = accountResponse[0];
  
  console.log(instance)
  
  const claimContract = new window.web3.eth.Contract(JSON.parse(process.env.REACT_APP_REWARD_ABI), rewardAddress);
  
  console.log(claimContract)
  
  const approve = await claimContract.methods.claim().send({from: instance});
  
  console.log(approve)

  const teamAmount = approve?.events?.Claim?.returnValues?.amount;

  console.log(teamAmount / Math.pow(10, 18), "amo")
  
  if(approve.transactionHash) {
    toast.success("Reward Pool added successfully", {
      position: "top-center",
    })
    setRewardLoading(false)
    createPost({
      from: account,
      to: rewardAddress,
      amount: floorFigure(teamAmount / Math.pow(10, 18), 5),
      txnid: approve.transactionHash,
      type: "rewardpool",
  
  
    })
    navigate("/Userwallet")

    const rewardContractSam = await claimContract.methods.rewardInfo(account).call();

    console.log(rewardContractSam, "val");
    setRewardBal(floorFigure(rewardContractSam / Math.pow(10, 18), 5));
    
    
   
  } else {
  toast.error("Please Try Again Later", {
    position: "top-center",
  })
  }
} catch(err) {
  if(err.code === 4001) {
    toast.info("User reject the Request ", {
      position: "top-center",
    })
    setRewardLoading(false)
  }
  console.log(err)
}

  
}
  
const reserveclaim = async () => {

try {
  
  await new Web3(window.web3.currentProvider);
  window.web3 = new Web3(window.web3.currentProvider);

  setReserveLoading(true)
  
  const accountResponse = await window.web3.eth.getAccounts();
  console.log(accountResponse)
  const instance = accountResponse[0];
  
  console.log(instance)
  
  const claimContract = new window.web3.eth.Contract(JSON.parse(process.env.REACT_APP_RESERVE_ABI), reserveAddress);
  
  console.log(claimContract)
  
  const approve = await claimContract.methods.claim().send({from: instance});
  
  console.log(approve)

  const reserveAmount = approve?.events?.Claim?.returnValues?.amount;

  
  if(approve.transactionHash) {
    toast.success("Reserve Claim added successfully", {
      position: "top-center",
    })
    setReserveLoading(false)
    createPost({
      from: account,
      to: reserveAddress,
      amount: floorFigure(reserveAmount / Math.pow(10, 18), 5),
      txnid: approve.transactionHash,
      type: "reserve",
    })
    navigate("/Userwallet")

    const reserveContractSam = await claimContract.methods.rewardInfo(account).call();

    console.log(reserveContractSam, "val");
    setReserveBal(floorFigure(reserveContractSam / Math.pow(10, 18), 5));
    
    
   
  } else {
  toast.error("Please Try Again Later", {
    position: "top-center",
  })
  }
} catch(err) {
  if(err.code === 4001) {
    toast.info("User reject the Request ", {
      position: "top-center",
    })
    setReserveLoading(false)
  }
  console.log(err)
}
  
}
  
const advisoryclaim = async () => {

try {

  await new Web3(window.web3.currentProvider);
  window.web3 = new Web3(window.web3.currentProvider);

  setAdvisoryLoading(true)
  
  const accountResponse = await window.web3.eth.getAccounts();
  console.log(accountResponse)
  const instance = accountResponse[0];
  
  console.log(instance)
  
  const claimContract = new window.web3.eth.Contract(JSON.parse(process.env.REACT_APP_ADVISORY_ABI), advisoryAddress);
  
  console.log(claimContract)
  
  const approve = await claimContract.methods.claim().send({from: instance});
  
  console.log(approve.transactionHash)

  const adviosryAmount = approve?.events?.Claim?.returnValues?.amount;

  
  if(approve.transactionHash) {
    toast.success("Advisory Claim added successfully", {
      position: "top-center",
    })
    setAdvisoryLoading(false)
    createPost({
      from: account,
      to: advisoryAddress,
      amount: floorFigure(adviosryAmount / Math.pow(10, 18), 5),
      txnid: approve.transactionHash,
      type: "advisory",
  
    })
    navigate("/Userwallet")

    const advisoryContractSam = await claimContract.methods.rewardInfo(account).call();

    console.log(advisoryContractSam, "val");
    setAdvisoryBal(floorFigure(advisoryContractSam / Math.pow(10, 18), 5));

  } else {
  toast.error("Please Try Again Later", {
    position: "top-center",
  })
  }
} catch(err) {
  if(err.code === 4001) {
    toast.info("User reject the request ", {
      position: "top-center",
    })
    setAdvisoryLoading(false)
  }
  console.log(err)
}

  
}
  
  
const partnershipclaim = async () => {

try {
  
  await new Web3(window.web3.currentProvider);
  window.web3 = new Web3(window.web3.currentProvider);

  setPartnershipLoading(true)
  
  const accountResponse = await window.web3.eth.getAccounts();
  console.log(accountResponse)
  const instance = accountResponse[0];
  
  console.log(instance)
  
  const claimContract = new window.web3.eth.Contract(JSON.parse(process.env.REACT_APP_PARTNERSHIP_ABI), partnershipAddress);
  
  console.log(claimContract);
  
  const approve = await claimContract.methods.claim().send({from: instance});
  
  console.log(approve.transactionHash)

  const ecosystemAmount = approve?.events?.Claim?.returnValues?.amount;


  console.log(approve)
  
  if(approve.transactionHash) {
    toast.success("Partnership Claim added successfully" , {
      position: "top-center",
    } )
    setPartnershipLoading(false)
    createPost({
      from: account,
      to: partnershipAddress,
      amount: floorFigure(ecosystemAmount / Math.pow(10, 18), 5),
      txnid: approve.transactionHash,
      type: "partnership",


    })
    navigate("/Userwallet")

    const partnershipContractSam = await claimContract.methods.rewardInfo(account).call();

    console.log(partnershipContractSam, "val");
    setPartnershipBal(floorFigure(partnershipContractSam / Math.pow(10, 18), 5));
    
    
 
  } else {
  toast.error("Please Try Again Later", {
    position: "top-center",
  })
  }
} catch(err) {
  if(err.code === 4001) {
    toast.info("User reject the request ", {
      position: "top-center",
    })
    setPartnershipLoading(false)
  }
  console.log(err)
}

  
  }

  







  return (
    <div>
       <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/> 
    <div class="cotainer">
<div class="row justify-content-center">
<div class="col-lg-9 col-md-12">
<div class="swapCard">
<div class="col-md-10 m-auto">

<div class="row">
<div class="col-md-6">
  <h5>Your Wallet Address
    <i class="fa fa-info-circle" data-toggle="tooltip" 
data-placement="top" title="Your Wallet Address containing Elysium Coin and BNB assets."></i>  </h5>
</div>
<div class="col-md-6">

<h3>
                <input className='cliam cliamInput' value={active ? account  : ""}></input>
    <a href="javascript:;" class="copy">
  </a>
  </h3>

  

</div>
          </div>

          
                
                <div class="row">
<div class="col-md-6">
  <h5>Operational Claim
    <i class="fa fa-info-circle" data-toggle="tooltip" 
data-placement="top" title="Your Wallet Address containing Elysium Coin and BNB assets."></i>  </h5>
</div>
<div class="col-md-6">

<h3>
                <input className='cliam cliamInput' value={active ? operationBal  : ""}></input>
    <a href="javascript:;" class="copy">

  </a>
  </h3>

  

</div>
                </div>

              { operationBal <= 0 ? null :   <div className='col-lg-12 text-end'>
  <button id='newone' onClick={() => operationclaim()} className={loading ? "claimsss" : "claimss"}>Operational Claim</button>
                </div> }

                <br />
               
                
                <div class="row">
<div class="col-md-6">
  <h5>Reward Claim
    <i class="fa fa-info-circle" data-toggle="tooltip" 
data-placement="top" title="Your Wallet Address containing Elysium Coin and BNB assets."></i>  </h5>
</div>
<div class="col-md-6">

<h3>
                <input className='cliam cliamInput' value={active ? rewardBal : ""}></input>
    <a href="javascript:;" class="copy">

  </a>
  </h3>

  

</div>
                </div>

                { rewardBal <= 0 ? null :   <div className='col-lg-12 text-end'>
  <button onClick={() => rewardclaim()} className={rewardloading ? "claimsss" : "claimss"}>Reward Claim</button>
                </div> }

            
                <br />

                <div class="row">
<div class="col-md-6">
  <h5>Advisory Claim
    <i class="fa fa-info-circle" data-toggle="tooltip" 
data-placement="top" title="Your Wallet Address containing Elysium Coin and BNB assets."></i>  </h5>
</div>
<div class="col-md-6">

<h3>
    <input className='cliam cliamInput' value={active ? advisoryBal : ""}></input>
    <a href="javascript:;" class="copy">
  </a>
  </h3>
</div>                  
</div>

               { advisoryBal <= 0 ? null :  <div className='col-lg-12 text-end'>
  <button onClick={() => advisoryclaim()} className={adviosryloading ? "claimsss" : "claimss"}>Advisory Claim</button>
                </div>}
                <br />

                <div class="row">
<div class="col-md-6">
  <h5>Partnership Claim
    <i class="fa fa-info-circle" data-toggle="tooltip" 
data-placement="top" title="Your Wallet Address containing Elysium Coin and BNB assets."></i>  </h5>
</div>
<div class="col-md-6">

<h3>
    <input className='cliam cliamInput' value={active ? partnershipBal : ""}></input>
    <a href="javascript:;" class="copy">
  </a>
  </h3>
</div>                  
</div>

               { partnershipBal <= 0 ? null :  <div className='col-lg-12 text-end'>
  <button onClick={() => partnershipclaim()} className={partnershiploading ? "claimsss" : "claimss"}>Partnership Claim</button>
                </div>}
                <br />

              
                   
                <div class="row">
<div class="col-md-6">
  <h5>Reserve Claim
    <i class="fa fa-info-circle" data-toggle="tooltip" 
data-placement="top" title="Your Wallet Address containing Elysium Coin and BNB assets."></i>  </h5>
</div>  
<div class="col-md-6">

<h3>
    <input className='cliam cliamInput' value={active ? reserveBal : ""}></input>
    <a href="javascript:;" class="copy">
  </a>
  </h3>
</div>      
                </div>
               

              
                { reserveBal <= 0 ? null :  <div className='col-lg-12 text-end'>
  <button onClick={() => reserveclaim()} className={reserveloading ? "claimsss" : "claimss"}>Reserve Claim</button>
                </div>}
                <br />


</div>
</div>





</div>
</div>
</div>
</div>
  )
}

export default Claim