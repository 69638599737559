import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const baseUrl = `https://apiuser.elysiumlila.com/`;
  

export const getUserDetails = createApi({
    reducerPath: 'Dashboard',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        getTransactions: builder.mutation({
            query: (newPost) => ({
                url: `/users/getOneTx`,
                method: 'POST',
                body: newPost,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',

                }
            })
        }),
        claimTransactions: builder.mutation({
            query: (newPost) => ({
                url: `/users/claim`,
                method: 'POST',
                body: newPost,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                }
            })
        }),
        userLogin: builder.mutation({
            query: (newPost) => ({
                url: `/users/login`,
                method: 'POST',
                body: newPost,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                }
            })
        }),
        userWalletBalance: builder.mutation({
            query: (newPost) => ({
                url: `/users/getwalletbalance`,
                method: 'POST',
                body: newPost,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                }
            })
        }),
        userSettings: builder.query({
            query: () => ({
                url: `/users/settings`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                }
            })
        }),
        userGetTXHistory: builder.mutation({
            query: (newPost) => ({
                url: `/users/getTxHistory`,
                method: 'POST',
                body: newPost,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                }
            })
        }),
        useReferral: builder.mutation({
            query: (newPost) => ({
                url: `/users/getrefTx`,
                method: 'POST',
                body: newPost,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                }
            })
        }),
        useBuyHistory: builder.mutation({
            query: (newPost) => ({
                url: `/tx/buynew`,
                method: 'POST',
                body: newPost,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                }
            })
        }),
        useBuyAdd: builder.mutation({
            query: (newPost) => ({
                url: `/users/buy`,
                method: 'POST',
                body: newPost,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                }
            })
        }),
        useStakeAdd: builder.mutation({
            query: (newPost) => ({
                url: `/users/stake`,
                method: 'POST',
                body: newPost,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                }
            })
        }),
        useBuyCount: builder.mutation({
            query: (newPost) => ({
                url: `/users/buytrans`,
                method: 'POST',
                body: newPost,
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                }
            })
        }),

    })
})

export const { useGetTransactionsMutation, useClaimTransactionsMutation, useUserLoginMutation, useUserWalletBalanceMutation, useUserSettingsQuery, useUserGetTXHistoryMutation, useUseReferralMutation, useUseBuyHistoryMutation, useUseBuyAddMutation, useUseStakeAddMutation, useUseBuyCountMutation } = getUserDetails;

