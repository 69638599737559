import React, { useEffect, useState } from 'react';
import { useUseReferralMutation, useUserGetTXHistoryMutation } from './store/ReduxApis';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLinkClickHandler } from 'react-router-dom';

function Referal({account, code, active, newCode}) {

  const [createPost, responseInfo] = useUserGetTXHistoryMutation();
  const [copied, setCopied] = useState(false);
  


  const [createPost2, responseInfo2] = useUseReferralMutation();


  console.log(responseInfo2, "referral");
  console.log(code)

  console.log(responseInfo2);

  const newPost = {
    "type": "refBonus",
    "user_wallet": account
  }





  useEffect( async() => {

 

    createPost2(newPost);


  }, [account])
  


 
 

  useEffect(() => {

    createPost()
   
  }, [])

  console.log(newCode, "800")

  useEffect(() => {
     clicked()
  }, [copied])

  const clicked = () => {
    if (copied) {
      toast.success("Copied ReferralCode", {
        position: "top-center",
      })
    }

  }



  




  return (
   
  <div>
        <main class="swapInput">
  <div class="cotainer">
    <div class="row justify-content-center">
      <div class="col-lg-9 col-md-12">
        <div class="swapCard">
          {active ?   <>
          
          {newCode !== undefined ?
                  <div style={{
                    display: "flex"}}>
                   <h3  onChange={(e) => setCopied(false)}>MY REFERRAL LINK : {code + newCode} </h3> 
                   
                  
                  <CopyToClipboard
          options={{ message: "" }}
          text={code + newCode}
          onCopy={() => setCopied(true)}
        >
          <i class="fa fa-copy" Address style={{margin: "8px 15px", cursor: "pointer", color: "blue"}} onClick={() => clicked()}></i>
                    </CopyToClipboard>
                      {copied ? <>
                        <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>   
                        <span style={{ fontSize: "18px", fontWeight: 600, color: "blue" }}>Copied.</span> 
                      </> : null}
                  </div>
  
  : ""}
  
  </> : "" }
        
               
                

<h3>MY REFERRAL AUDIT TRAIL</h3>
    
<div class="table-responsive lockedtable mb-5">
  <table class="table table-bordered">
    <thead>
      <tr>
      
        <th scope="col">From Address</th>
        <th scope="col">To Address</th>
        <th scope="col">Type</th>
                        <th scope="col">Date</th>
                        <th scope="col">Bonus Amount</th>
        <th>Txhash</th>
                      </tr>
                     
      
                        {responseInfo2 && responseInfo2?.data?.txns?.map(e => (
                          <tr>
                            <td scope="col">{e.email}</td>
                            <td scope="col">{e.to}</td>
                            <td scope="col">{e.type}</td>
                            <td scope="col">{new Date(Number(e.timestamp)).toLocaleDateString()}</td>
                            <td scope="col">{e.targetAmount} BNB</td>
                            <td><a href={`https://bscscan.com/tx/${e.txHash}`} target="_blank">{e.txHash}</a></td>
                   
                            </tr>
                        )) }
  
    </thead>


    
    
     
  </table>
</div>
      

							
							

         
         
        </div>

        <app-footer></app-footer> 
        



      </div>
    </div>
  </div>
</main>
  </div>
  )
}

export default Referal