import React, { useEffect, useState } from 'react'
import { useGetTransactionsMutation, useUserSettingsQuery, useClaimTransactionsMutation } from './store/ReduxApis'
import Footer from './Footer'
import Web3 from 'web3'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MainPage({  active, account, defaultAddress, walletBalance ,NugenBalence,  }) {

  const [createPost2, responseInfo2] = useClaimTransactionsMutation();
  const [createPost, responseInfo] = useGetTransactionsMutation()
  const [airdropLoading, setAirdropLoading] = useState(false);
  const [presaleLoading, setPresaleLoading] = useState(false);

  const [values, setValues] = useState();

  const [airdropBal, setAirdropBal] = useState("")
  const [icoBal, setIcoBal] = useState("");

  console.log(responseInfo2, "response")

  const data = useUserSettingsQuery();
  const nugenToken = data?.data?.data?.smartContractAddress?.elsyium
  const airdropAddress = data?.data?.data?.smartContractAddress?.airdrop
  const icoAddress = data?.data?.data?.smartContractAddress?.ico;




  console.log(data);

  console.log(defaultAddress)

  const newPost = {
    user_wallet: defaultAddress,
    
  }

  function floorFigure(figure, decimals){
    if (!decimals) decimals = 2;
    var d = Math.pow(10,decimals);
    return (parseInt(figure*d)/d).toFixed(decimals);
};


const balance = async () => {
  try {
 

    await new Web3(window.web3.currentProvider);
    window.web3 = new Web3(window.web3.currentProvider);

   
  
    const product = await new window.web3.eth.Contract(
      JSON.parse(process.env.REACT_APP_AIRDROP_ABI),
      airdropAddress
    );

    const productContract = await product.methods.rewardInfo(defaultAddress).call();

    console.log(productContract, "777");
    setAirdropBal(floorFigure(productContract / Math.pow(10, 18), 5));

    console.log(productContract, "productBal");

    const icoBalence = await new window.web3.eth.Contract(
      JSON.parse(process.env.REACT_APP_BUY_ABI),
      icoAddress
    );

   

    const icoContract = await icoBalence.methods.rewardInfo(defaultAddress).call();

    console.log(icoContract, "800");
    setIcoBal(floorFigure(icoContract / Math.pow(10, 18), 5));
   

  } catch (err) {
    console.log(err, "eerr");
  }
};



useEffect(() => {
  balance()
})



   
const airdropclaim = async () => {


try {
  
  await new Web3(window.web3.currentProvider);
  window.web3 = new Web3(window.web3.currentProvider);



  setAirdropLoading(true)
  
  const accountResponse = await window.web3.eth.getAccounts();
  console.log(accountResponse)
  const instance = accountResponse[0];
  
  console.log(instance)
  
  const claimContract = new window.web3.eth.Contract(JSON.parse(process.env.REACT_APP_ADVISORY_ABI), airdropAddress);
  
  console.log(claimContract)
  
  const approve = await claimContract.methods.claim().send({from: instance});
  
  console.log(approve.transactionHash)

  const adviosryAmount = approve?.events?.Claim?.returnValues?.amount;

  
  if(approve.transactionHash) {
    toast.success("Airdrop Claim added successfully", {
      position: "top-center",
    })
    setAirdropLoading(false)
    createPost2({
      from: defaultAddress,
      to: airdropAddress,
      amount: floorFigure(adviosryAmount / Math.pow(10, 18), 5),
      txnid: approve.transactionHash,
      type: "airdrop",
  
    })

    
    const partnershipContractSam = await claimContract.methods.rewardInfo(account).call();

    console.log(partnershipContractSam, "val");
    setAirdropBal(floorFigure(partnershipContractSam / Math.pow(10, 18), 5));
    


    
  } else {
  toast.error("Please Try Again Later", {
    position: "top-center",
  })
  }
} catch(err) {
  if(err.code === 4001) {
    toast.info("User reject the request ", {
      position: "top-center",
    })
    setAirdropLoading(false)
  }
  console.log(err)
}
  
}


   
const presaleClaim = async () => {


  try {
    
    await new Web3(window.web3.currentProvider);
    window.web3 = new Web3(window.web3.currentProvider);

    
  
    setPresaleLoading(true)
    
    const accountResponse = await window.web3.eth.getAccounts();
    console.log(accountResponse)
    const instance = accountResponse[0];
    
    console.log(instance)

    
    const claimContract = new window.web3.eth.Contract(JSON.parse(process.env.REACT_APP_BUY_ABI), icoAddress);

   
    
    console.log(claimContract)

    

   
    const approve = await claimContract.methods.claim().send({from: instance, gas: 900000 });

    const firstClaim = approve?.events?.Claim[0]?.returnValues?.releasedAmount

    const firstClaimSample = approve?.events?.Claim?.returnValues?.releasedAmount


    const secondClaim = approve?.events?.Claim[1]?.returnValues?.releasedAmount

    const thirdClaim = approve?.events?.Claim[2]?.returnValues?.releasedAmount


    console.log(firstClaim, secondClaim, "presale")
    
    console.log(approve, "presale")

    let transaction= await window.web3.eth.getTransactionReceipt(`${approve.transactionHash}`)
     console.log(transaction, "presale")
  

    if(approve.transactionHash) {

      if(!secondClaim && !thirdClaim) {
     
        const total = Number(firstClaimSample);
        console.log(total, "presale")
        console.log("first", "presale") 
        createPost2({
          from: defaultAddress,
          to: icoAddress,
          amount: floorFigure(total / Math.pow(10, 18), 5),
          txnid: approve.transactionHash,
          type: "presale",
      
        })
  
  
  
      
  
      } else if(secondClaim && !thirdClaim ) {
        const total = Number(firstClaim) + Number(secondClaim) 
        console.log(total, "presale")
        console.log("second", "presale") 
        createPost2({
          from: defaultAddress,
          to: icoAddress,
          amount: floorFigure(total / Math.pow(10, 18), 5),
          txnid: approve.transactionHash,
          type: "presale",
        })
      }  else {
        const total = Number(firstClaim) + Number(secondClaim) + Number(thirdClaim) 
        console.log(total, "presale")
        console.log("third", "presale") 
        createPost2({
          from: defaultAddress,
          to: icoAddress,
          amount: floorFigure(total / Math.pow(10, 18), 5),
          txnid: approve.transactionHash,
          type: "presale",
        })
      }                           


      const icoContract = await claimContract.methods.rewardInfo(defaultAddress).call();


      toast.success("Presale Claim added successfully", {
        position: "top-center",
      })

    

      setPresaleLoading(false)
    



      const icoContracts = await claimContract.methods.rewardInfo(defaultAddress).call();
      setIcoBal(icoContracts)
    } else {
    toast.error("Please Try Again Later", {
      position: "top-center",
    })
    }
  } catch(err) {
    if(err.code === 4001) {
      toast.info("User reject the request ", {
        position: "top-center",
      })
      setPresaleLoading(false)
    }
    console.log(err)
  }
    
  }


 

  useEffect(() => {
    
    createPost(newPost)

  }, [defaultAddress])

  console.log(responseInfo)

  const date = new Date(1645685466961).getDate();

  console.log(date)

  



  return (
      

    <div>
      <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>     

           
     
      
    <div class="cotainer">
<div class="row justify-content-center">
<div class="col-lg-9 col-md-12">
<div class="swapCard">
<div class="col-md-10 m-auto">

<div class="row">
<div class="col-md-6">
<h5>Contract Address <i class="fa fa-info-circle" data-toggle="tooltip" 
  data-placement="top" title="Token Contract Address"></i>  </h5>
</div>
<div class="col-md-6">
<h3>
<input type="text" className="input" value={active ? nugenToken  : ""}></input>
  <a href="javascript:;" class="copy">
</a>
</h3>
</div>
</div>
<div class="row">
<div class="col-md-6">
<h5>Your Wallet Address
  <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" title="Your Wallet Address containing Elysium Coin and BNB assets."></i>  </h5>
</div>
<div class="col-md-6">
<h3>
 <input className='input' value={active ? defaultAddress  : ""}></input>
  <a href="javascript:;" class="copy">
</a>
</h3>



</div>
</div>




<div class="row"> 
<div class="col-md-6">
</div>

</div> 

<div class="row">
<div class="col-md-6">
<h5>BNB Balance</h5>
</div>
<div class="col-md-6">
<h3>
                <input className="input" value={active ? walletBalance  : ""}></input>
              </h3>
</div>
                </div>
                
                <div class="row">
<div class="col-md-6">
<h5>Elysium Balance</h5>
</div>
<div class="col-md-6">
<h3>
                <input className="input" value={active ? NugenBalence  : ""}></input>
              </h3>
</div>
                </div>
                
                <div class="row">
<div class="col-md-6">
<h5>Presale Claim Balance</h5>
</div>
<div class="col-md-6">
<h3>
                <input className="input" value={active ? icoBal  : ""}></input>
              </h3>
</div>
                </div>
                
<div class="row">
<div class="col-md-6">

</div>
{active ? <div class="col-md-6">
  
{ icoBal <= 0 ? null :  <button className={presaleLoading ? "claimsss" : "claimss"} onClick={(e) => presaleClaim(e)} >Presale Claim</button> }
 
</div> : ""}

                </div>

                <br />


                <div class="row">
<div class="col-md-6">
<h5>Airdrop Claim Balance</h5>
</div>
<div class="col-md-6">
<h3>
                <input className="input" value={active ? airdropBal : ""}></input>
              </h3>
</div>
                </div>
                
<div class="row">
<div class="col-md-6">

</div>
{active ? <div class="col-md-6">
{ airdropBal <= 0 ? null :  <button className={airdropLoading ? "claimsss" : "claimss"} onClick={(e) => airdropclaim(e)} >Airdrop Claim</button> }
 
</div> : ""}
                </div>
                
<div class="row"> 
<div class="col-md-6">
<h3 class="mt-5 mb-4">Transaction Histories <i class="fa fa-info-circle" data-toggle="tooltip" 
data-placement="top" title="Transaction Histories"></i></h3>
</div>

</div>    

{ active ?  
<div class="table-responsive mb-5">
<table class="table table-bordered">
<thead>
  <tr>
   
     <th>WALLET</th> 
    <th scope="col">Type</th>
    <th scope="col">AMOUNT</th>
    <th scope="col">TxHash</th>
    <th scope="col">DATE</th>

  </tr>
</thead>
<tbody >
                {responseInfo && responseInfo?.data?.txns?.map(e => (
    <tr>
                    <td>{e.from}</td>
              
                    <td>{e.type}</td>
                    <td>{e.sourceAmount}</td>
                    <td><a href={`https://bscscan.com/tx/${e.txHash}`} target="_blank">{e.txHash}</a></td>
                    <td>{new Date(Number(e.timestamp)).toLocaleDateString()}</td>
  </tr>
  ))  }

</tbody>

 
</table>
                </div> : null }
                

                


  
                

                

   




</div>
</div>





</div>
</div>
</div>
</div>
      
  )
}

export default MainPage