import React from 'react'


function Navbar({btn, data}) {
  console.log(data, "new")

  const site = data?.data?.data?.socialMedia?.siteLinks;
  console.log(site, "seconds")

  return (
    <div className='header'>
         <div className='topbar' >  
    <div className='phising'>
        <h5><span className='warning'>PHISING WARNING: </span>please make sure if you are visiting <span>{site}</span> - check the URL carefully  </h5>
    </div>
    <i class="fas fa-times color"onClick={btn}></i>
   </div>
   </div>
  )
}

export default Navbar