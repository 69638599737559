import React, { useState} from 'react'
import Homebannerimg from "../assets/img/banner_top_img.png"
import { Link, useNavigate } from 'react-router-dom';
import { useUserSettingsQuery } from "./store/ReduxApis"
import Countdown from 'react-countdown';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Home({ active }){

    const navigate = useNavigate();

    const data = useUserSettingsQuery();
    const buyAddress = data?.data?.data.smartContractAddress?.ico;
    const elysium = data?.data?.data?.smartContractAddress?.elsyium;

    const buy = () => {

        if(!active) {
            toast.info("Please Connect Wallet to continue ... ", {
                position: "top-center"
            })
        } else {
            navigate("/buy")
        }
    }

    return(
        <div className='homeBody'>
             <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/> 
        <div>        
            <div class="page-bg"></div>
            <div class="animation-wrapper">
                <div class="particle particle-1"></div>
                <div class="particle particle-2"></div>
                <div class="particle particle-3"></div>
                <div class="particle particle-4"></div>
            </div>

            <section className='homeBanner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <div className='homeBanner_text mb-4'>
                                <h1>Elysium</h1>
                                <p>Elysium is a land ownership platform that exquisitely blends crypto, NFTs, and DeFi through a sophisticated infrastructure. The platform consists of land tiles or plots where each plot is a square piece of land (75*75 feet) which is tokenized as BEP-721 NFTs. Users can purchase, sell, stake, or even develop these NFTs to meet their business essentials. Every plot is mapped to the Google Earth Map to bestow a 3D interactive experience overall. Elysium assists brands and individuals in bringing users all over the world to a shared virtual space to establish an ideal community for their products/services. The platform amalgamates the physical and digital worlds through splendid VR/AR techniques.</p>
                                                             
                            </div>
                        </div> 
                        <div className='col-lg-6 col-md-6'>
                            <div className='homeBanner_img'>
                                <img src={Homebannerimg} className='img-fluid' />
                            </div>
                        </div>                                               
                    </div>                      
                </div>                
            </section>

            <section className='addressSec'>
                <div className='container'>
                    <div className='row addressSec_box mx-2'>
                        <div className='col-lg-6 col-md-6'>
                            <Countdown className='timer' date={"2023-01-19 18:30"}></Countdown>
                            <button onClick={() => buy()} className='btn-hover color-6'>Buy Now</button>  
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className='addressSec_add'>
                                <h5 className='pb-3'><span>Presale address :</span> <a href={`https://bscscan.com/address/${buyAddress}`} target="_blank">{buyAddress}</a> </h5>
                                <h5><span>Token address :</span> <a href={`https://bscscan.com/address/${elysium}`} target="_blank">{elysium}</a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

    

            <section className='disclaimerSec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h4>Disclaimer</h4>
                            <p>The Elysium token has been designed as an entry pass for people to 
                                participate in lotto games developed by Elysium that are built into the 
                                token code. The Elysium token doesn’t provide any inherent value in 
                                isolation from the entire Elysium ecosystem. Neither does it give the 
                                user any rights or privileges. Also, the decision to invest in any of 
                                Elysium lotto games is wholly based on your discretion and Elysium would 
                                bear no responsibility for any financial losses that you may accrue as a 
                                result of investing in Elysium token. </p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <p className='copyRight'>© 2022 Elysium. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </section>

        </div>     
        <Footer></Footer>  
        </div> 
    )
}

export default Home

