import React, { useState } from 'react';


import {  Nav, Navbar, Container } from 'react-bootstrap';



import Logo from "../assets/img/logo.png";
import { Link } from 'react-router-dom';





function Header({  defaultAccont, account,  connectWallet, disconnectWallet, active }) {

  const [expanded, setExpanded] = useState(false);



  console.log(defaultAccont)

  console.log(account)

  
    return (
      <div>
  
        <Navbar className='navMain' bg="light" expand="lg" expanded={expanded}>
        
             <Container>
              
                               <Navbar.Brand  href="/"><img className='logos' src={Logo} /></Navbar.Brand>
                               <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} />
               <Navbar.Collapse id="basic-navbar-nav">
             
               <Nav className="me-auto"> 
                { active ?  <>
                  <Nav.Link className="navName" ><Link style={{ color: "#ffffff", fontSize: "15px" }} onClick={() => setExpanded(false)} to="/">Home</Link></Nav.Link>  
                <Nav.Link className="navName" ><Link onClick={() => setExpanded(false)} style={{ color: "#ffffff", fontSize: "15px" }}  to="/Userwallet">User Wallet</Link></Nav.Link>
                <Nav.Link className="navName"><Link onClick={() => setExpanded(false)} style={{ color: "#ffffff", fontSize: "15px" }} to="/buy">Private Sale - Buy</Link></Nav.Link>
                <Nav.Link className="navName" ><Link onClick={() => setExpanded(false)} style={{ color: "#ffffff", fontSize: "15px" }} to="/claim">Claim</Link></Nav.Link>
                <Nav.Link className="navName" ><Link onClick={() => setExpanded(false)} style={{ color: "#ffffff", fontSize: "15px" }} to="/referal">Referral</Link></Nav.Link>
            
                </>  : <>
                <Nav.Link className="navName" ><Link onClick={() => setExpanded(false)} style={{ color: "#ffffff", fontSize: "15px" }} to="/">Home</Link></Nav.Link>  
                <Nav.Link className="navName sample" ><Link onClick={() => setExpanded(false)} style={{ color: "#ffffff", fontSize: "15px" }} to="/Userwallet">User Wallet</Link></Nav.Link>
                <Nav.Link className="navName sample"><Link onClick={() => setExpanded(false)} style={{ color: "#ffffff", fontSize: "15px" }} to="/buy">Private Sale - Buy</Link></Nav.Link>
                <Nav.Link className="navName sample" ><Link onClick={() => setExpanded(false)} style={{ color: "#ffffff", fontSize: "15px" }} to="/claim">Claim</Link></Nav.Link>
                <Nav.Link className="navName sample" ><Link onClick={() => setExpanded(false)} style={{ color: "#ffffff", fontSize: "15px" }} to="/referal">Referral</Link></Nav.Link>
            
                </> }
                                         
                 
                <div className='navcorner'>
                  <div className='navItems'>
                            
                
                     {!active && <button className='btn-hover color-6' variant="contained" onClick={connectWallet}> Connect Wallet </button>}
            {active && <button className='btn-hover color-6' variant="contained" onClick={disconnectWallet}>Disconnect Wallet</button>}

                  </div>
                </div>
              </Nav>
                               </Navbar.Collapse>
                               
             </Container>

           </Navbar> 
        
       
             
        
         
          
   </div>
   
   
  )
}

export default Header