import React, { useEffect, useState } from 'react'
import Web3 from 'web3';
import { useUserSettingsQuery, useUseBuyHistoryMutation, useUseBuyAddMutation, useUseBuyCountMutation } from './store/ReduxApis';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Buy({defaultAddress, active, refcode}) {


  const [amountValue, setAmountValue] = useState("");
  const [convert, setConvert] = useState("");
  const [amounts, setAmounts] = useState("")
  const [receiveAmount, setReceiveAmount] = useState("")
  const [loading, setLoading] = useState(false);

  const [buyValue, setBuyValue] = useState("")
  
  
  const [value, setValue] = useState("")
  const data = useUserSettingsQuery();
  const [createPost2, responseInfo2] = useUseBuyAddMutation();
  const [createPost3, responseInfo3] = useUseBuyHistoryMutation();

  const [createPost5, responseInfo5] = useUseBuyCountMutation();

  console.log(responseInfo5, "800")


  console.log(responseInfo3)



  const buyAddress = data?.data?.data?.smartContractAddress?.ico

  const min = data?.data?.data?.min_contribution;
  const max = data?.data?.data?.max_contribution
  const price = data?.data?.data?.price

  const saleid = data?.data?.data?.sale_id;
  const sale = data?.data?.data?.sale

  console.log(saleid, sale, "300");


  console.log(buyAddress, "token")

  console.log(data)

  function floorFigure(figure, decimals){
    if (!decimals) decimals = 2;
    var d = Math.pow(10, decimals);
    return (parseInt(figure*d)/d).toFixed(decimals);
};

  useEffect(async() => {

    
await new Web3(window.web3.currentProvider);
window.web3 = new Web3(window.web3.currentProvider);



const claimContract = new window.web3.eth.Contract(JSON.parse(process.env.REACT_APP_BUY_ABI), buyAddress);


const passValue = convert * Math.pow(10, 18)

const newNumber = passValue.toLocaleString('fullwide', {useGrouping:false})


const calcualteOfferInBNB = await claimContract.methods.calcualteOfferInBNB(sale, newNumber).call();
setReceiveAmount(calcualteOfferInBNB * Math.pow(10, 18));


    const receiveAmount = (calcualteOfferInBNB / Math.pow(10, 18))
    console.log(receiveAmount, "8800");
    setReceiveAmount(floorFigure(receiveAmount, 6))
    console.log(receiveAmount)

  }, [convert])

  function addLeadingZeros(num, totalLength) {
    return String(num).padEnd(totalLength, '0');
  }
  
  const timestampValue = Date.now() / 1000;
  

  console.log(min, max, "600")


  console.log(timestampValue, "value")

  

const buyFunctions = async (e) => {

    try {
      if(!active) {
        alert("Please Connect Wallet to continue ... ")
        return false;
     }

 

     
    


await new Web3(window.web3.currentProvider);
window.web3 = new Web3(window.web3.currentProvider);



console.log(responseInfo5, "800")

setLoading(true)
console.log(receiveAmount, "500")
const amount = receiveAmount * Math.pow(10, 21);

console.log(amount, "800")
setAmounts()

const elysium_token = convert * price * Math.pow(10, 10);

console.log(elysium_token, "800")

console.log(typeof(convert), "convert") 

const val = addLeadingZeros(convert, 20);

console.log(val,  'convert')
console.log(amount, "200")
    
const accountResponse = await window.web3.eth.getAccounts();
console.log(accountResponse);
const instance = accountResponse[0];

console.log(instance);
setValue(instance);

const claimContract = new window.web3.eth.Contract(JSON.parse(process.env.REACT_APP_BUY_ABI), buyAddress);

console.log(claimContract, "text");

const saleClose = await claimContract.methods.endBlock(sale).call();



if (convert < Number(min) || convert > Number(max))  {
  console.log(convert, min, max , "2000")
  toast.info('Minimum Token Eligible ' + min + " " + " and Maximum Token Eligible " + max, {
    position: "top-center",
  });
  setLoading(false)
  return false;
}



if(convert != Math.floor(convert)) {

  toast.info("Decimal value not allowed!", {
    position: "top-center",
  })
  setLoading(false)
  return false
}




if (timestampValue >= saleClose) {

  toast.info("Presale is Closed", {
    position: "top-center",
  })
  setLoading(false);
  return false
}


const passValue = convert * Math.pow(10, 18)

const newNumber = passValue.toLocaleString('fullwide', {useGrouping:false})


const calcualteOfferInBNB = await claimContract.methods.calcualteOfferInBNB(sale, newNumber).call();

console.log(calcualteOfferInBNB, receiveAmount,  "8800")

console.log(newNumber, receiveAmount, "8800")
 
      const approve = await claimContract.methods.buyOffer(sale, `${newNumber}`).send({ from: instance, value: calcualteOfferInBNB, gas: 900000 });

      if(approve.transactionHash) {
        toast.success("successfully Purchased", {
          position: "top-center",
        })
        setLoading(false)
        createPost2({
          from: defaultAddress,
          to: buyAddress,
          amount: convert,
          bnbamount: receiveAmount,
          txnid: approve.transactionHash,
          type: "buy"
        })

     

     

     

       
      } else {
        toast.error("Transaction Failed", {
          position: "top-center",
        })
        setLoading(false)
      }
    } catch(err) {
      if(err.code === 4001) {
        toast.info("User reject the Request ", {
          position: "top-center",
        })
        setLoading(false)
      } else {
        toast.error("Transaction Failed", {
          position: "top-center",
        })
        setLoading(false)
      }
      
     
      console.log(err)
    }

   
  }
  console.log(receiveAmount, "400")
  console.log(data?.data?.data?.max_contribution)



 




return (
  <div>
   <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
/>        
    <div class="cotainer">
      <div class="row justify-content-center">
        <div class="col-lg-9 col-md-12">
          <div class="swapCard">
            <div class="col-md-10 m-auto">

              <div class="row">
                <div class="col-md-6">
                  <h5>Your Wallet Address <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" title="Your Wallet Address containing Elysium Coin and BNB assets."></i>  </h5>
                </div>
                <div class="col-md-6">
                  <h3>
                    <input className='cliam cliamInput' value={defaultAddress}></input>
                    <a href="javascript:;" class="copy">
                    </a>
                  </h3>
                </div>
              </div>  
              { refcode !== "" ?  <div class="row">
                <div class="col-md-6">
                  <h5>Referred By User Address <i class="fa fa-info-circle" data-toggle="tooltip" data-placement="top" title="Your Referred By User Address containing Elysium Coin and BNB assets."></i>  </h5>
                </div>
                <div class="col-md-6">
                  <h3>
                    <input className='cliam cliamInput' value={refcode}></input>
                    <a href="javascript:;" class="copy">
                    </a>
                  </h3>
                </div>
              </div>   : <></>    }

              <div class="row">
            <div class="col-md-6">
              <h5>Elysium Tokens To Buy            :
                <i class="fa fa-info-circle" data-toggle="tooltip" 
            data-placement="top" title="Please enter the amount of Elysium Coin you want to buy"></i>  </h5>
            </div>
            <div class="col-md-6">
              <span>
                <input type="number" onChange={(e) => setConvert(e.target.value)} placeholder="Enter the amount of ElysiumToken" class="form-control"
                name="fromAmount"  />
              </span>
            </div>
          </div>



          <div class="row mt-3">
            <div class="col-md-6">
              <h5>BNB            :
                <i class="fa fa-info-circle" data-toggle="tooltip" 
            data-placement="top" title="The total amount in BNB equivalent needed to pay for your purchase of Elysium Coin"></i>  </h5>
            </div>
            <div class="col-md-6">
                  <span><input class="form-control" onChange={(e) => setAmountValue(e.target.value)} placeholder="Amount of BNB needed" readonly value={receiveAmount} /></span>
            </div>
          </div>

              <div class="row"> 
                <div class="col-md-6">
                  </div>
              </div> 
                <div class="row">
                <div class="col-md-6">
                              </div>
                <div class="col-md-6">
                <h5>Minimum Buy Amount :<span> {data?.data?.data?.min_contribution} Elysium Coin </span></h5>
   
                    
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                 
                </div>
                <div class="col-md-6">
               
                  <h5>Maximum Buy Amount : <span>{data?.data?.data?.max_contribution} Elysium Coin</span></h5>
                    <p></p>
                </div>
              </div>

           

              <div class="row">
                <div class="col-md-6">
                 
                </div>
                <div class="col-md-6">  
                <h5>1 BNB <span> :{data?.data?.data?.price} Elysium Coin</span></h5>  
                </div>
              </div>
              
            {active ?   <div className='col-lg-12 text-end'>
                <button className={loading ? "claimsss btn-hover color-6" : "claimss btn-hover color-6"} onClick={(e) => buyFunctions(e)}>Buy</button>
              </div> : "" }
          
            </div>
                
          

          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Buy